import Router from 'next/router'

const redirect = (path, context) => {
  if (context) {
    context.res.statusCode = 302
    context.res.setHeader('Location', path)
  } else {
    Router.push(path)
  }
}

export default redirect
