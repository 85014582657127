/*eslint-disable*/
import _ from 'lodash'
import redirect from './redirect'
import Cookies from './cookies'
import sessions from './sessions'
const HOST = process.env.NEXT_PUBLIC_HOST
const BASE_URL = process.env.NEXT_PUBLIC_API_SERVER

export default {
  async get (endpoint, params = {}, context = null, custom_headers = {}) {
    let url = BASE_URL + endpoint
    if (!_.isEmpty(params)) {
      url = `${url}?${new URLSearchParams(params)}`
    }
    const response = await fetch(url, {
      // mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'cn-route': Cookies.get('is_china', context)? 'cn' : null,
        ...custom_headers,
        'scope':'quancheng',
        Authorization:endpoint.includes('tutor/')? Cookies.get('auth_token_tutor',context): Cookies.get('auth_token', context),
      },
      signal: params.signal,
    })
    if (response.status > 201) {
      throw new Error(`API GET ${url} failed: ${await response.text()}`)
    }
    const result = await response.json()
    if (result.error === '登陆失败') {
      const path = context?.req.url || window?.location.pathname
      if (path.startsWith('/user')) {
        redirect('/', context)
      }
    }
    return result
  },
  async post (endpoint, params = {}) {
    const options = {
      method: 'post',
      headers: {
        'scope':'quancheng',
        Authorization: endpoint.includes('tutor/')? Cookies.get('auth_token_tutor'): Cookies.get('auth_token'),
      },
    }
    if (Object.prototype.toString.call(params) === '[object FormData]') {
      options.body = params
    } else {
      options.body = JSON.stringify(params)
      options.headers['Content-type'] = 'application/json'
    }
    const response = await fetch(BASE_URL + endpoint, options)
    if (response.status > 201) {
      throw new Error(`API POST failed: ${await response.text()}`)
    }
    const result = await response.json()
    if (result.error === '登陆失败') {
      sessions.set('user', null)
      const path = window.location.pathname
      if (path.startsWith('/user')) {
        redirect('/')
      }
    }
    return result
  },
  async put(endpoint, params = {}) {
    const options = {
      method: 'put',
      mode: 'cors',
      headers: {
        'scope':'quancheng',
        Authorization: endpoint.includes('tutor/')? Cookies.get('auth_token_tutor'): Cookies.get('auth_token'),
      },
    }
    if (Object.prototype.toString.call(params) === '[object FormData]') {
      options.body = params
    } else {
      options.body = JSON.stringify(params)
      options.headers['Content-type'] = 'application/json'
    }
    const response = await fetch(BASE_URL + endpoint, options)
    if (response.status > 201) {
      throw new Error(`API POST failed: ${await response.text()}`)
    }
    const result = await response.json()
    return result
  },
  async fetchSVG(mathml) {
    const formData = new FormData()
    formData.append('mml', mathml)
    const options = {
      method: 'post',
      body: formData,
    }
    const res = await fetch(
      `${HOST}/wirispluginengine/integration/showimage`,
      options
    )
    const result = await res.json()
    return result?.status === 'ok' ? result.result?.content : ''
  },
  async getUserInfo(context = null) {
    const endpoint = 'user/user_info'
    const url = BASE_URL + endpoint
    const response = await fetch(url, {
      headers: {
        'scope':'quancheng',
        'Content-Type': 'application/json',
        Authorization: Cookies.get('auth_token', context),
      },
    })
    if (response.status > 201) {
      throw new Error(`API GET ${url} failed: ${await response.text()}`)
    }
    const result = await response.json()
    return result
  },
  async getTutorInfo(context = null) {
    const endpoint = 'tutor/easyke_mentors/dashboard'
    const url = BASE_URL + endpoint
    const response = await fetch(url, {
      headers: {
        'scope':'quancheng',
        'Content-Type': 'application/json',
        Authorization: Cookies.get('auth_token_tutor', context),
      },
    })
    if (response.status > 201) {
      throw new Error(`API GET ${url} failed: ${await response.text()}`)
    }
    const result = await response.json()
    return result
  },
}
