import ClientCookies from 'cookie-cutter'
import ServerCookies from 'cookies'

export default {
  get(name, context) {
    if (context) {
      return new ServerCookies(context.req, context.res).get(name)
    }
    return ClientCookies.get(name, {path: '/'})
  },
  set(name, value, context) {
    if (context) {
      new ServerCookies(context.req, context.res).set(name, value)
    } else {
      ClientCookies.set(name, value, {path: '/'})
    }
  },
}
